import React from "react";
import { X } from "lucide-react";

const HelpPopup = ({ toggleHelp }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">AI Transcriber Help</h2>
          <button
            onClick={toggleHelp}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        {/* Help Sections */}
        <div className="space-y-4">
          <section>
            <h3 className="font-semibold mb-2">Transcription</h3>
            <p className="text-gray-700">
              Click the microphone button to start or stop recording. The app transcribes your speech in real-time.
            </p>
          </section>

          <section>
            <h3 className="font-semibold mb-2">Notes</h3>
            <p className="text-gray-700">
              Type notes in the text area and press Enter or click the send button to add them to the transcript.
            </p>
          </section>

          <section>
            <h3 className="font-semibold mb-2">AI Assistant</h3>
            <p className="text-gray-700">
              Click the chat icon to open the AI assistant. You can ask questions or generate summaries and action items from your meeting.
            </p>
          </section>

          <section>
            <h3 className="font-semibold mb-2">Export</h3>
            <p className="text-gray-700">
              Use the Export button in the header to save your transcript as a PDF file.
            </p>
          </section>
        </div>

        {/* Branding and Developer Contact */}
        <div className="mt-6 pt-4 border-t border-gray-200">
          <h4 className="text-lg font-semibold">Developed by</h4>
          <p className="text-gray-700">Luis Despiau</p>
          <p className="text-gray-500 text-sm">El Desarrollador™</p>
          <p className="text-gray-600 text-sm">
            <a href="mailto:despiau@gmail.com" className="hover:underline">
              despiau@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpPopup;
