import React, { useState } from "react";
import {
  ChevronLeft,
  Settings,
  HelpCircle,
  LogOut,
  Edit2,
  Save,
  Trash2,
  FileText,
  PlusCircle,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Sidebar = ({
  isOpen,
  toggleSidebar,
  messages,
  updateMessage,
  deleteMessage,
  clearConversation,
  toggleHelp,
  addMessage,
}) => {
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editedNoteText, setEditedNoteText] = useState("");
  const [newNoteText, setNewNoteText] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);

  const handleEditNote = (note) => {
    setEditingNoteId(note.id);
    setEditedNoteText(note.text);
  };

  const handleSaveNote = () => {
    if (editingNoteId !== null) {
      updateMessage(editingNoteId, editedNoteText);
      setEditingNoteId(null);
      setEditedNoteText("");
    }
  };

  const handleDeleteNote = (id) => {
    deleteMessage(id);
  };

  const handleClearConversation = () => {
    setShowConfirmDialog(true);
  };

  const confirmClearConversation = () => {
    clearConversation();
    setShowConfirmDialog(false);
  };

  const handleAddNote = () => {
    if (newNoteText.trim() !== "") {
      addMessage(newNoteText, "Note");
      setNewNoteText("");
      setIsAddingNote(false);
    }
  };

  const handleTextareaKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleAddNote();
    }
  };

  const getMessageBackgroundColor = (sender) => {
    switch (sender) {
      case "System":
        return "bg-white";
      case "Note":
        return "bg-blue-300";
      default:
        return "bg-white";
    }
  };

  return (
    <div
      className={`w-64 bg-slate-800 text-white p-4 transition-all duration-300 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 fixed md:static h-full z-20 flex flex-col`}
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold flex items-center">
          <FileText className="mr-2" size={24} /> Notes
        </h2>
        <button
          onClick={toggleSidebar}
          className="md:hidden text-white hover:text-gray-300 transition-colors"
        >
          <ChevronLeft size={24} />
        </button>
      </div>

      <button
        onClick={() => setIsAddingNote(true)}
        className="w-full mb-4 bg-blue-600 text-white px-2 py-2 rounded hover:bg-blue-700 transition-colors flex items-center justify-center"
      >
        <PlusCircle size={18} className="mr-2" /> Add New Note
      </button>

      {isAddingNote && (
        <div className="bg-blue-300 p-2 rounded shadow-md mb-4">
          <textarea
            value={newNoteText}
            onChange={(e) => setNewNoteText(e.target.value)}
            onKeyPress={handleTextareaKeyPress}
            placeholder="Enter note text..."
            className="w-full h-20 p-1 text-black rounded"
          />
          <div className="flex justify-end mt-2">
            <button
              onClick={handleAddNote}
              className="text-blue-600 hover:text-blue-800 mr-2"
            >
              <Save size={16} />
            </button>
            <button
              onClick={() => setIsAddingNote(false)}
              className="text-red-400 hover:text-red-800"
            >
              <Trash2 size={16} />
            </button>
          </div>
        </div>
      )}

      <div className="flex-grow overflow-y-auto space-y-2 mb-4">
        {messages
          .filter((msg) => msg.sender === "Note")
          .map((note) => (
            <div key={note.id} className="bg-blue-300 p-2 rounded shadow-md">
              {editingNoteId === note.id ? (
                <textarea
                  value={editedNoteText}
                  onChange={(e) => setEditedNoteText(e.target.value)}
                  className="w-full h-20 p-1 text-black rounded"
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSaveNote();
                    }
                  }}
                />
              ) : (
                <ReactMarkdown remarkPlugins={[remarkGfm]} className="text-black">
                  {note.text}
                </ReactMarkdown>
              )}
              <div className="flex justify-end mt-2">
                {editingNoteId === note.id ? (
                  <button
                    onClick={handleSaveNote}
                    className="text-blue-600 hover:text-blue-800 mr-2"
                  >
                    <Save size={16} />
                  </button>
                ) : (
                  <button
                    onClick={() => handleEditNote(note)}
                    className="text-gray-700 hover:text-gray-800 mr-2"
                  >
                    <Edit2 size={16} />
                  </button>
                )}
                <button
                  onClick={() => handleDeleteNote(note.id)}
                  className="text-red-400 hover:text-red-800"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
          ))}
      </div>

      <div className="border-t border-gray-700 pt-4 space-y-2">
        <button className="w-full text-left p-2 hover:bg-gray-700 rounded transition-colors flex items-center">
          <Settings size={18} className="mr-2" /> Settings
        </button>
        <button
          onClick={handleClearConversation}
          className="w-full text-left p-2 hover:bg-gray-700 rounded transition-colors flex items-center"
        >
          <Trash2 size={18} className="mr-2" /> Clear Transcript
        </button>
        <button
          onClick={toggleHelp}
          className="w-full text-left p-2 hover:bg-gray-700 rounded transition-colors flex items-center"
        >
          <HelpCircle size={18} className="mr-2" /> Help
        </button>
        <button className="w-full text-left p-2 hover:bg-gray-700 rounded transition-colors flex items-center">
          <LogOut size={18} className="mr-2" /> Logout
        </button>
      </div>

      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-5">
          <div className="bg-white p-4 rounded-lg text-black w-full max-w-md mx-auto">
            <p>Are you sure you want to clear the transcript?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={confirmClearConversation}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
