import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import ChatContainer from "./ChatContainer";
import Footer from "./Footer";
import Chatbot from "./Chatbot";
import { MessageCircle } from "lucide-react";
import { generateChatResponse } from "../utils/openai";
import HelpPopup from "./HelpPopup";

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMeeting, setCurrentMeeting] = useState({
    id: Date.now(),
    startTime: new Date(),
    messages: [],
  });
  const [notes, setNotes] = useState([
    { id: 1, text: "Initial meeting note", timestamp: new Date() },
  ]);
  const [chatbotMessages, setChatbotMessages] = useState([
    {
      id: 1,
      text: "Hello! I'm your AI assistant. How can I help you today?",
      sender: "assistant",
    },
  ]);
  const [helpOpen, setHelpOpen] = useState(false);

  const toggleChatbot = () => setChatbotOpen(!chatbotOpen);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleHelp = () => setHelpOpen(!helpOpen);

  const toggleRecording = () => {
    setIsRecording((prevIsRecording) => {
      if (!prevIsRecording) {
        if (currentMeeting.messages.length === 0) {
          startMeeting();
        }
      }
      return !prevIsRecording;
    });
  };

  const startMeeting = () => {
    if (currentMeeting.messages.length === 0) {
      const startMessage = {
        id: Date.now(),
        text: "Meeting started",
        sender: "System",
        timestamp: new Date(),
      };
      setMessages([startMessage]);
      setCurrentMeeting((prevMeeting) => ({
        ...prevMeeting,
        messages: [startMessage],
      }));
    }
  };

  const addMessage = (text, sender = "User") => {
    const newMessage = { id: Date.now(), text, sender, timestamp: new Date() };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setCurrentMeeting((prevMeeting) => ({
      ...prevMeeting,
      messages: [...prevMeeting.messages, newMessage],
    }));
  };

  const updateMessage = (id, newText) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === id ? { ...msg, text: newText } : msg
      )
    );
    setCurrentMeeting((prevMeeting) => ({
      ...prevMeeting,
      messages: prevMeeting.messages.map((msg) =>
        msg.id === id ? { ...msg, text: newText } : msg
      ),
    }));
  };

  const deleteMessage = (id) => {
    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
    setCurrentMeeting((prevMeeting) => ({
      ...prevMeeting,
      messages: prevMeeting.messages.filter((msg) => msg.id !== id),
    }));
  };

  const clearConversation = () => {
    setMessages([]);
    setCurrentMeeting({
      id: Date.now(),
      startTime: new Date(),
      messages: [],
    });
    setIsRecording(false);
  };

  const addChatbotMessage = (text, sender = "assistant") => {
    const newMessage = { id: Date.now(), text, sender };
    setChatbotMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const handleChatbotAction = async (action) => {
    let prompt = "";
    const basePrompt = `
        You are an advanced AI assistant integrated with a meeting transcription app. You have real-time access to the meeting transcript. Your task is to analyze the transcript provided and generate structured information based on the specified action. Ensure that your response is detailed, formatted in markdown using bold for emphasis, and captures all critical elements.

        Here is the current meeting transcript:
    `;
    const transcriptText = messages.map((msg) => `${msg.sender}: ${msg.text}`).join("\n");

    if (action === "summary") {
        prompt = `
            ${basePrompt}

            --- Transcript Start ---
            ${transcriptText}
            --- Transcript End ---

            Please generate a comprehensive summary that highlights the most important key points discussed during the meeting. Make sure to include:
            - **Major topics covered**
            - **Important decisions made**
            - **Any agreements or consensus reached**
            - **Key points raised by different participants**
        `;
    } else if (action === "action items") {
        prompt = `
            ${basePrompt}

            --- Transcript Start ---
            ${transcriptText}
            --- Transcript End ---

            Please extract and generate a detailed list of actionable items from the meeting. Each action item should include:
            - **A clear and concise description of the task**
            - **The person or team responsible for the task**
            - **Any deadlines or due dates mentioned**
            - **Additional context or notes if available**
        `;
    }

    addChatbotMessage(`Generating ${action}...`, "assistant");

    try {
        const messagesForApi = [
            {
                role: "system",
                content: "You are a helpful assistant for a meeting transcription app.",
            },
            {
                role: "user",
                content: prompt,
            },
        ];

        const response = await generateChatResponse(messagesForApi);
        addChatbotMessage(response, "assistant");
    } catch (error) {
        console.error("Error getting chatbot response:", error);
        addChatbotMessage(
            "Sorry, I encountered an error. Please try again.",
            "assistant"
        );
    }
};

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar
        isOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        messages={messages}
        updateMessage={updateMessage}
        deleteMessage={deleteMessage}
        clearConversation={clearConversation}
        toggleHelp={toggleHelp}
        addMessage={addMessage}
      />
      <div className="flex-1 flex flex-col bg-slate-100 overflow-auto"> {/* Added bg-slate-100 for main container */}
        <Header
          toggleSidebar={toggleSidebar}
          messages={messages}
          currentMeeting={currentMeeting}
          notes={notes} 
        />
        <ChatContainer messages={messages} />
        <Footer
          isRecording={isRecording}
          toggleRecording={toggleRecording}
          addMessage={addMessage}
        />
        <div className="bg-slate-200 text-center p-2 text-sm text-gray-800">
          AI Transcriber™ Beta 1.2 | © {new Date().getFullYear()} Luis Despiau, El Desarrollador
        </div>
      </div>
      <button
        onClick={toggleChatbot}
        className={`fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors z-30 ${chatbotOpen ? "hidden" : ""}`}
      >
        <MessageCircle size={24} />
      </button>
      {chatbotOpen && (
        <Chatbot
          toggleChatbot={toggleChatbot}
          chatbotMessages={chatbotMessages}
          addChatbotMessage={addChatbotMessage}
          handleChatbotAction={handleChatbotAction}
          transcriptionMessages={messages}
        />
      )}
      {helpOpen && <HelpPopup toggleHelp={toggleHelp} />}
    </div>
  );
};

export default App;
