import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ChatContainer = ({ messages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getMessageBackgroundColor = (sender) => {
    switch (sender) {
      case "System":
        return "bg-white";
      case "Note":
        return "bg-blue-300";
      default:
        return "bg-white";
    }
  };

  const renderMessageText = (text) => {
    const containsMarkdown = /[#*\[\]()`~]/.test(text);
    const containsHtml = /<\/?[a-z][\s\S]*>/i.test(text);

    if (containsMarkdown || containsHtml) {
      return <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>;
    } else {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    }
  };

  return (
    <div
      className="chat-container overflow-y-auto p-4 bg-slate-200" // Cambié bg-slate-200
      style={{
        width: "100%", // Cambié el width a 100% para llenar todo el espacio
        height: "100vh", // Cambié el alto a 100vh para llenar todo el viewport
      }}
    >
      {messages.map((msg) => (
        <div
          key={msg.id}
          className={`mb-4 p-3 rounded shadow ${getMessageBackgroundColor(
            msg.sender
          )}`}
        >
          <div className="flex justify-between items-start">
            <strong>
              {msg.sender === "System" ? "Conversation" : msg.sender}:
            </strong>
            <span className="text-xs text-gray-800">
              {msg.timestamp.toLocaleTimeString()}
            </span>
          </div>
          <div className="mt-1">
            {renderMessageText(msg.text)}
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatContainer;
