import React, { useState, useEffect, useRef } from "react";
import { Mic, Send } from "lucide-react";

const Footer = ({ isRecording, toggleRecording, addMessage }) => {
  const [transcript, setTranscript] = useState("");
  const [liveTranscript, setLiveTranscript] = useState("");
  const [language, setLanguage] = useState("en-US");
  const recognitionRef = useRef(null);
  const isRecordingRef = useRef(isRecording);

  useEffect(() => {
    isRecordingRef.current = isRecording;
  }, [isRecording]);

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = language;

      recognitionRef.current.onresult = (event) => {
        const results = event.results;
        const lastResult = results[results.length - 1];
        const transcriptText = lastResult[0].transcript.trim();
        setLiveTranscript(transcriptText);

        if (lastResult.isFinal) {
          const message = formatMessage(transcriptText);
          if (message) {
            addMessage(message, "User");
          }
        }
      };

      recognitionRef.current.onend = () => {
        if (isRecordingRef.current) {
          try {
            recognitionRef.current.start();
          } catch (error) {
            console.error("Failed to restart recognition:", error);
          }
        }
      };
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, [language, addMessage]);

  useEffect(() => {
    if (recognitionRef.current) {
      recognitionRef.current.lang = language;
      if (isRecording) {
        try {
          recognitionRef.current.start();
        } catch (error) {
          console.error("Failed to start recognition:", error);
          try {
            recognitionRef.current.stop();
            setTimeout(() => {
              recognitionRef.current.start();
            }, 100);
          } catch (retryError) {
            console.error("Failed to restart recognition:", retryError);
          }
        }
      } else {
        try {
          recognitionRef.current.stop();
        } catch (error) {
          console.error("Failed to stop recognition:", error);
        }
        setLiveTranscript("");
      }
    }
  }, [isRecording, language]);

  const handleSend = () => {
    if (transcript) {
      addMessage(transcript, "Note");
      setTranscript("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    if (recognitionRef.current) {
      try {
        recognitionRef.current.stop();
      } catch (error) {
        console.error("Failed to stop recognition:", error);
      }
      recognitionRef.current.lang = e.target.value;
      if (isRecordingRef.current) {
        try {
          recognitionRef.current.start();
        } catch (error) {
          console.error("Failed to restart recognition:", error);
        }
      }
    }
  };

  const formatMessage = (message) => {
    const words = message.split(" ");
    const uniqueWords = [...new Set(words)];
    for (let i = 0; i < uniqueWords.length; i++) {
      if (i === 0 || uniqueWords[i - 1].endsWith(".")) {
        uniqueWords[i] =
          uniqueWords[i][0].toUpperCase() + uniqueWords[i].substring(1);
      }
    }
    return uniqueWords.join(" ");
  };

  return (
    <footer className="bg-slate-200 p-4">
      <div className="bg-white rounded shadow">
        <div className="bg-slate-600 text-white p-2 flex justify-between items-center rounded-t-lg">
          <span>Live Transcript</span>
          <select
            className="bg-gray-500 text-white rounded p-1 hover:bg-gray-500 transition-colors"
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="en-US">English (US)</option>
            <option value="es-ES">Spanish</option>
            <option value="fr-FR">French</option>
          </select>
        </div>
        <div className="p-4">
          <div className="mb-4 text-gray-900 font-medium">
            {isRecording
              ? liveTranscript || "Recording..."
              : "Waiting for speech..."}
          </div>
          <div className="flex">
            <textarea
              className="flex-1 border rounded-l p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Type your note here"
              value={transcript}
              onChange={(e) => setTranscript(e.target.value)}
              onKeyPress={handleKeyPress}
              rows="2" // Altura predeterminada de 2 filas
              style={{ resize: "none" }} // Desactiva el control de redimensionamiento manual
            />
            <button
              onClick={handleSend}
              className="bg-blue-600 text-white px-4 py-2 rounded-r hover:bg-blue-600 transition-colors"
            >
              <Send size={20} />
            </button>
            <button
              onClick={toggleRecording}
              className={`${
                isRecording
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-gray-300 hover:bg-gray-400"
              } text-white px-4 py-2 rounded-full ml-2 transition-colors`}
            >
              <Mic size={20} />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
