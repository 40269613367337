import React from "react";
import { Menu, Save, AudioLines, Copy } from "lucide-react";
import jsPDF from "jspdf";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Header = ({ toggleSidebar, messages, currentMeeting, notes }) => {
  const exportToPDF = () => {
    if (!currentMeeting || !messages.length) {
      console.log("No messages to export");
      return;
    }

    const timestamp = new Date().toLocaleString().replace(/[/,:]/g, '-');
    const fileName = `Meeting_Transcription_${timestamp}.pdf`;
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const margin = 15;
    const maxLineWidth = pageWidth - 2 * margin;
    let y = margin;

    doc.setFontSize(12);
    doc.text("Meeting Transcription", margin, y);
    y += 10;

    doc.setFontSize(10);
    doc.text(
      `Meeting started at: ${currentMeeting.startTime.toLocaleString()}`,
      margin,
      y
    );
    y += 10;

    // Adding Notes Section
    doc.text("Notes:", margin, y);
    y += 10;
    notes.forEach((note) => {
      const lines = doc.splitTextToSize(
        `[${note.timestamp.toLocaleTimeString()}] Note: ${note.text}`,
        maxLineWidth
      );

      lines.forEach((line) => {
        if (y + 10 > pageHeight - margin) {
          doc.addPage();
          y = margin;
        }
        doc.text(line, margin, y);
        y += 5;
      });
      y += 5;
    });

    // Adding Chat History Section
    doc.text("Chat History:", margin, y);
    y += 10;
    messages.forEach((msg) => {
      const lines = doc.splitTextToSize(
        `[${msg.timestamp.toLocaleTimeString()}] ${msg.sender}: ${msg.text}`,
        maxLineWidth
      );

      lines.forEach((line) => {
        if (y + 10 > pageHeight - margin) {
          doc.addPage();
          y = margin;
        }
        doc.text(line, margin, y);
        y += 5;
      });
      y += 5;
    });

    doc.save(fileName);
  };

  const copyTranscriptText = messages
    .map((msg) => `[${msg.timestamp.toLocaleTimeString()}] ${msg.sender}: ${msg.text}`)
    .join("\n");

  return (
    <header className="bg-slate-700 shadow p-4 flex justify-between items-center">
      <div className="flex items-center">
        <button
          onClick={toggleSidebar}
          className="mr-4 md:hidden text-gray-600 hover:text-gray-800 transition-colors"
        >
          <Menu />
        </button>
        <h1 className="text-xl font-bold text-white flex items-center">
          <AudioLines className="mr-2" size={24} /> AI Transcriber
        </h1>
      </div>
      <div className="flex space-x-2">
        <CopyToClipboard text={copyTranscriptText}>
          <button
            className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600 transition-colors flex items-center"
          >
            <Copy className="inline mr-1" size={16} /> Copy
          </button>
        </CopyToClipboard>
        <button
          onClick={exportToPDF}
          className="bg-green-500 text-white px-3 py-2 rounded hover:bg-green-600 transition-colors flex items-center"
        >
          <Save className="inline mr-1" size={16} /> Export
        </button>
      </div>
    </header>
  );
};

export default Header;
