import React, { useState, useEffect } from "react";
import { X, Maximize2, Minimize2, Send, Copy, FileText } from "lucide-react"; // Added FileText icon for "Generate"
import { generateChatResponse } from "../utils/openai";
import ReactMarkdown from "react-markdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import remarkGfm from "remark-gfm";
import prompts from "../utils/prompts";

const Chatbot = ({
  toggleChatbot,
  chatbotMessages,
  addChatbotMessage,
  transcriptionMessages,
}) => {
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState("general_summary");
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Fullscreen toggle
  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };

  // Handles sending messages
  const handleSend = async () => {
    const userInput = input.trim();
    const isUsingPrompt = !userInput; // Check if we're using the selected prompt instead of a custom message
    const messageContent = isUsingPrompt ? prompts[selectedPrompt] : userInput;

    if (!messageContent) return; // Do nothing if there's no input or prompt text

    setIsLoading(true);
    setInput(""); // Clear input after sending

    if (isUsingPrompt) {
      // Show the status message in chat for prompts
      addChatbotMessage(`Generating ${selectedPrompt.replace(/_/g, " ")}...`, "status");
    } else {
      // Show user's message in chat for custom input
      addChatbotMessage(userInput, "user");
    }

    try {
      const transcriptText = transcriptionMessages
        .map((msg) => msg.text)
        .join(" ");
      
      const promptText = `
        You are an advanced AI assistant integrated with a meeting transcription app. Analyze the transcript and generate information based on the specified action. Here is the transcript:

        --- Transcript Start ---
        ${transcriptText}
        --- Transcript End ---

        ${messageContent}
      `;

      const response = await generateChatResponse([{ role: "user", content: promptText }]);

      // Add response to chatbotMessages after generating
      addChatbotMessage(response, "assistant");
    } catch (error) {
      console.error("Error getting chatbot response:", error);
      addChatbotMessage("Sorry, I encountered an error. Please try again.", "assistant");
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    const chatbotMessagesElement = document.getElementById("chatbot-messages");
    if (chatbotMessagesElement) {
      chatbotMessagesElement.scrollTop = chatbotMessagesElement.scrollHeight;
    }
  }, [chatbotMessages]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div
      className={`fixed bottom-4 right-4 ${isFullScreen
        ? "w-[calc(100vw-32px)] h-[calc(100vh-32px)] p-4"
        : "w-full md:w-[400px] lg:w-[450px] h-[60vh] max-h-[600px]"
        } bg-white rounded-lg shadow-xl flex flex-col z-30`}
    >
      <div className="bg-blue-600 text-white p-3 rounded-t-lg flex justify-between items-center">
        <span>AI Assistant</span>
        <div className="flex space-x-2">
          <button onClick={toggleFullScreen} className="text-white hover:text-gray-200 transition-colors">
            {isFullScreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
          </button>
          <button onClick={toggleChatbot} className="text-white hover:text-gray-200 transition-colors">
            <X size={20} />
          </button>
        </div>
      </div>

      {/* Chat Messages Container */}
      <div id="chatbot-messages" className="flex-1 overflow-y-auto p-4 max-h-[60vh]">
        {chatbotMessages.map((msg, index) => (
          <div
            key={index}
            className={`mb-4 p-3 rounded relative ${msg.sender === "assistant" ? "bg-blue-100 text-blue-800" : "bg-gray-100 text-gray-800"}`}
          >
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {msg.text}
            </ReactMarkdown>
            {msg.sender !== "status" && ( // Hide copy button for status messages
              <CopyToClipboard text={msg.text}>
                <button
                  className="absolute bottom-1 right-1 text-gray-500 hover:text-gray-700"
                  style={{ fontSize: "0.9em" }}
                >
                  <Copy size={14} />
                </button>
              </CopyToClipboard>
            )}
          </div>
        ))}
      </div>

      {/* Loading Spinner */}
      {isLoading && (
        <div className="text-center text-gray-500 animate-pulse">
          Generating your response...
        </div>
      )}

      {/* Prompt Selector and Generate Button */}
      <div className="p-4 border-t space-y-2">
        <div className="flex items-center">
          <select
            onChange={(e) => setSelectedPrompt(e.target.value)}
            value={selectedPrompt}
            className="flex-1 p-2 rounded bg-gray-200"
          >
            {Object.keys(prompts).map((key) => (
              <option key={key} value={key}>
                {key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}
              </option>
            ))}
          </select>
          <button
            onClick={handleSend}
            className="bg-blue-600 text-white px-4 py-2 rounded ml-2 flex items-center justify-center transition-colors hover:bg-blue-700"
            style={{ height: "42px" }}
            disabled={isLoading}
          >
            <FileText size={20} /> {/* Always shows FileText icon to indicate "Generate from Prompt" */}
          </button>
        </div>

        {/* Input Text Box and Send Button */}
        <div className="flex">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask a question or select a prompt..."
            className="flex-1 border rounded-l p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onKeyPress={handleKeyPress}
            disabled={isLoading}
            rows="2"
            style={{ resize: "none" }}
          />
          <button
            onClick={handleSend}
            className={`bg-blue-600 text-white px-4 py-2 rounded-r transition-colors ${isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"}`}
            disabled={isLoading}
          >
            <Send size={20} /> {/* Always shows Send icon for custom messages */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
